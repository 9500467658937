import axios, { Axios } from 'axios';
import { i18n } from './i18n';
import store from './store';
import router from './router';
// import { truncate } from 'fs';

axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';

const DEBUG = process.env.VUE_APP_DEBUG;
const ENDPOINT = process.env.VUE_APP_ENDPOINT;
const UPLOAD_ENDPOINT = process.env.VUE_APP_UPLOAD_ENDPOINT;

const CancelToken = axios.CancelToken;
let cancel;

const axiosInstance = axios.create({
	baseURL: ENDPOINT,
	timeout: 1000 * 30
});

const uploadAxios = axios.create({
	baseURL: UPLOAD_ENDPOINT,
	timeout: 1000 * 30
});

export const cancelPending = async (source) => {

	await source.cancel('Canceled by user');
	return true;

}

export const cancelRequests = (id) => {

	if (typeof cancel === 'function') {

		cancel('user cancel');
		return true;

	}

	return false;

};

export const isCancel = (err) => {

	return axios.isCancel(err);

};

axiosInstance.interceptors.response.use(response => {

	/*	if (DEBUG) {

		console.info("✉️ ", response);

	}*/

	return response;

}, async error => {

	if (error.response) {

		if (error.response.status === 0) {

			return Promise.reject(new Error('Request Timed Out'));

		}

		if (
			error.response.status === 403
			|| error.response.data && error.response.data.errorMessage === "Token expired or doesn't exist"
			|| error.response.data && error.response.data.errorMessage === "UserToken Not Valid"
		) {

			// console.debug('api.js interceptors forcedLogoutAction start', JSON.stringify(error,null,2));
			// await store.dispatch('forcedLogoutAction', false);
			// console.debug('api.js interceptors forcedLogoutAction done');

			if (router.currentRoute.fullPath != '/auth/login') {

				router.push({
					name: 'Login',
					// query: (router.currentRoute.fullPath !== '/') ? {redirect: encodeURI(router.currentRoute.fullPath)} : {}
				});

			}

			// location.reload();

		}

		if (DEBUG) {

			console.debug("✉️ ERROR response", error.response.status, '\n', JSON.stringify(error.response, null, 2));

		}

	} else if (error.request) {

		// The request was made but no response was received
		// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
		// http.ClientRequest in node.js
		if (DEBUG) {

			console.debug("✉️ ERROR request", error.request, '\n', JSON.stringify(error.request.getAllResponseHeaders(), null, 2));

		}

	} else {

		// Something happened in setting up the request that triggered an Error
		if (DEBUG) {

			console.debug("✉️ ERROR message", error.message);

		}

	}

	return Promise.reject(error);

});

const authorized = () => {

	if (!store || !store.state || !store.state.auth || !store.state.auth.authUserToken) {

		console.log('Authorization token missing');

		router.push({
			name: 'Login',
			// query: (router.currentRoute.fullPath !== '/') ? {redirect: encodeURI(router.currentRoute.fullPath)} : {}
		});

		throw new Error('Authorization token missing.');

	}

	// console.log('store.state.auth.authUserToken', store.state.auth.authUserToken);

	axios.defaults.headers.common.Authorization = store.state.auth.authUserToken;

	axiosInstance.interceptors.request.use((config) => {
		// Modify the headers before the request is sent
		config.headers.Authorization = store.state.auth.authUserToken;
		return config;
	});

};

const getId = () => {

	if (store.state
		&& store.state.application
		&& store.state.application.info
		&& store.state.application.info.data.id
	) {

		return store.state.application.info.data.id;

	}

};

export const login = async (payload) => {

	console.log('api.js login payload', payload);

	let result = await axiosInstance.post('/users/login', payload);

	console.log('api.js login result', result);

	return result;

};

export const session = async () => {

	authorized();

	let result = await axiosInstance.get('/users/session');

	return result.data;

};

export const logout = async () => {

	await authorized();

	let logoutResult = await axiosInstance.get('/users/logout');

	console.log('logoutResult', logoutResult);

	return logoutResult;

};

export const changePassword = (payload) => {

	authorized();

	return axiosInstance.post('/users/password', payload);

};

export const summary = async (payload) => {

	authorized();

	let result = await axiosInstance.get('/applications/summary', payload);

	return result.data.results;

};

export const getOptions = async () => {

	authorized();

	let result = await axiosInstance.get('/form-options');

	console.log('result', result);

	return result.data;

};

export const getProgramAssetTypes = async () => {

	authorized();

	let result = await axiosInstance.get('/program/asset-types?programId=194');

	return result.data;

};

export const saveApplication = async (payload) => {

	console.log('saveApplication payload', payload);

	if (!payload.token) {
		authorized();
	}

	console.log('pass authorized');

	payload.id = payload.id || getId();

	let result = await axiosInstance.post('/applications/save', payload);

	return result.data.application;

};

export const saveCoBorrower = async (payload) => {

	authorized();

	payload.id = payload.id || getId();

	let result = await axiosInstance.post('/applications/save-co-applicant', payload);

	return result.data.application;

};

export const submitApplication = async (id) => {

	authorized();

	id = id || getId();

	let result = await axiosInstance.get(`/applications/submit/${id}`);

	return result.data.application;

};

export const callAction = async (payload) => {

	authorized();

	let result = await axiosInstance.post(`/action?action=${payload.action}&id=${payload.id}&for=${payload.for}&domain=${payload.domain}&dealer=${payload.dealer}&purpose=${payload.purpose}`, payload);

	return result;

};

export const getApplication = async (id, token = null) => {

	if (!token) {
		authorized();
	}

	id = id || getId();

	let result = await axiosInstance.get(`/applications/get/${id}`, {
		params: {
			token: token || null
		}
	});

	return result.data;

};

export const getStatus = async (id) => {

	authorized();

	id = id || getId();

	let result = await axiosInstance.get(`/application/status/${id}`, {
		cancelToken: new CancelToken(function executor(c) {

			// An executor function receives a cancel function as a parameter
			cancel = c;

		})
	});

	return result.data;

};

export const getPermissions = async (id, source) => {

	authorized();

	id = id || getId();

	let result = await axiosInstance.get(`/application/permissions/${id}`, {
		cancelToken: source.token
	});

	return result.data;

};

export const runEvent = async (id, eventId) => {

	authorized();

	let result = await axiosInstance.get(`/application/event/${id}?eventId=${eventId}`);

	return result.data;

};

export const scan = (payload) => {

	authorized();

	let id = getId();

	let formData = new FormData();
	formData.append('fileInfo', payload);
	formData.append('fileType', payload.type);

	/*var reader = new FileReader();

	reader.onloadend = function() {

		console.log('RESULT', reader.result);

	};

	reader.readAsDataURL(payload);

	console.log(reader);*/

	return axiosInstance.post(`/applications/scan/${id}`, formData, {
		headers: {
			'Content-Type': false,
			'Authorization': store.state.auth.authUserToken
		}
	});

};


export const getSignNowStatusApi = async (payload) => {

	let result = await axiosInstance.get(`/get-sign-status/${payload.id}`, payload, {
		headers: {
			'Authorization': store.state.auth.authUserToken
		}
	});

	console.log('result', result);

	return result.data


}

export const upload = async (payload, file) => {

	console.log('upload payload', payload);

	// authorized();
	
	let signedUrl = await axiosInstance.post(`/applications/get-upload-url/${payload.id}`, payload, {
		headers: {
			'Authorization': store.state.auth.authUserToken
		}
	});

	console.log('signedUrl', signedUrl.data.url);

	let formData = new FormData();
	formData.append('file', file.file);

	console.log('file', file.file);
	
	delete axios.defaults.headers.common.Authorization;

	const s3Axios = axios.create({
		baseURL: signedUrl.data.url,
		timeout: 1000 * 3000
	});

	let s3Result = await s3Axios.put(signedUrl.data.url, file.file, {
		headers: {
			// 'Content-Type': 'multipart/form-data'
		}
	});

	console.log('s3Result', s3Result);

	return s3Result;

};

export const uploadToEdge = async (payload) => {

	let result = await axiosInstance.get(`/applications/merge-and-upload/${payload.id}`, payload, {
		headers: {
			'Authorization': store.state.auth.authUserToken
		}
	});

	if (result.errorMessage) {
		return result;
	}
	
	return result.data.result;

}

export const share = (payload) => {

	authorized();

	payload.id = payload.id || getId();

	return axiosInstance.post('/share', payload);

};

export const search = async (status) => {

	authorized();

	let result = await axiosInstance.get(`/applications/search?status=${status}`);

	return result.data.results;

};

// TODO: replace hard-coded programId
export const getAssetTypes = async (id) => {

	authorized();

	let result = await axiosInstance.get(`/program/asset-types?programId=${id}`);

	console.log('getAssetTypes ', result);

	return result.data;

};

// payload: programId, assetTypeId
export const getAssetSubTypes = async (payload) => {

	authorized();

	let result = await axiosInstance.get(`/program/asset-sub-types?programId=194&assetTypeId=${payload.assetTypeId}`);

	console.log('getAssetSubTypes ', result);

	return result.data;

};

export const addAsset = async (payload) => {

	authorized();

	let assetId = payload.assetId ? 'assetId=' + payload.assetId : '';

	let installationDate = '';
	if (payload.installationDate) {
		installationDate = '&installationDate=' + payload.installationDate.replace(/\//g, '-');
		// console.log(installationDate);
		// return;
	}

	// TODO: construct URL params with loop
	let result = await axiosInstance.get(`/application/add-asset/${payload.id}?assetName=${payload.assetName}&assetTypeId=${payload.assetTypeId}&assetSubTypeId=${payload.assetSubTypeId}&serialNumber=${payload.serialNumber}&retailCost=${payload.retailCost}&${assetId}${installationDate}`);

	console.log('addAsset result', result);

	return result.data.result;

};

export const deleteAsset = async (id, assetId) => {

	authorized();

	let result = await axiosInstance.get(`/application/delete-asset/${id}?assetId=${assetId}`);

	console.log('deleteAsset result', result);

	return result.data.result;

};

export const getAssets = async (id) => {

	authorized();

	let result = await axiosInstance.get(`/application/get-assets/${id}`);

	console.log('getAssets result', result);

	return result.data.assets;

};

/** id is optional, used to check if assets are added */
export const getPrograms = async (id) => {

	authorized();

	let result = await axiosInstance.get('/programs', {
		params: {
			id: id
		}
	});

	// console.log('getPrograms result', result);

	return result.data;

};

export const getProgramOptions = async (payload) => {

	// console.log('getProgramOptions payload', payload);

	authorized();

	// let appId = payload.id ? "id=" + payload.id : '';
	// let amount = payload.amount ? "amount=" + payload.amount : '';

	let result = await axiosInstance.get('/program/options', {
		params: payload
	});

	// console.log('getProgramOptions result', result);

	return result.data;

};

export const getMonthlyPayment = async (payload) => {

	console.log('getMonthlyPayment payload', payload);

	authorized();

	let leaseDate = '';
	let installationDate = '';

	try {
		leaseDate = '&leaseDate=' + payload.leaseDate.replace(/\//g, '-');
		installationDate = '&installationDate=' + payload.installationDate.replace(/\//g, '-');
	} catch (error) {}

	let result;
	if (payload.sendToEdge) {
		result = await axiosInstance.get(`/application/save-program/${payload.id}?programId=${payload.programId}&termId=${payload.termId}${leaseDate}${installationDate}`);
	} else {
		result = await axiosInstance.get(`/program/payment/${payload.id}?programId=${payload.programId}&termId=${payload.termId}${leaseDate}${installationDate}`);
	}
	
	console.log('getMonthlyPayment result', result);

	return result.data;

};


export const saveProgram = async (payload) => {

	console.log('saveProgram payload', payload);

	authorized();

	let leaseDate = '';

	try {
		leaseDate = '&leaseDate=' + payload.leaseDate.replace(/\//g, '-');
	} catch (error) {}

	let result = await axiosInstance.get(`/application/save-program/${payload.id}?programId=${payload.programId}&termId=${payload.termId}${leaseDate}`);

	console.log('saveProgram result', result);

	return result.data;

};

export const uploadFile = async (payload) => {

	authorized();

	let result = await axiosInstance.post(`/applications/upload/${payload.id}`);

	console.log('uploadFile result', result);

	return result.data;

};


export const sendEmailInvite = async (payload) => {

	authorized();

	console.log('payload', payload);

	let result = await axiosInstance.get(`/sign/${payload.id}`, {
		params: {
			onlyDealer: payload.onlyDealer,
			signNowInvite: payload.signNowInvite
		}
	});

	console.log('result:', result);

	return result.data;

};

export const downloadPdf = async (payload) => {

	authorized();

	console.log('payload', payload);

	let result = await uploadAxios.get(`/docusign/downloadPdf/${payload.id}`, {
		params: {
		}
	});

	console.log('result:', result);

	return result.data;

};

export const sendUploadTrigger = async (payload) => {

	authorized();

	console.log('id', payload.id);
	
	// uploadAxios

	let result = await uploadAxios.get(`/docusign/upload-to-s3/${payload.id}?envelopeId=${payload.envelopeId}`);

	console.log('sendUploadTrigger result:', result);

	return result;

}

export const checkSignStatus = async (payload) => {

	if (!payload.envelopeId) {
		return;
	}

	authorized();

	console.log('id', payload.id);
	
	// uploadAxios

	let result;

	try {
		
		result = await uploadAxios.get(`/check-sign/${payload.id}?envelopeId=${payload.envelopeId}`);

	} catch (error) {

		console.log('error', error);

		return false;
		
	}

	console.log('checkSignStatus result:', result);

	return result;

}

export const checkUploadsStatus = async (id) => {

	authorized();

	console.log('id', id);

	let result;

	try {
		
		result = await uploadAxios.get(`/applications/check-upload-status/${id}`);

	} catch (error) {

		console.log('error', error);
		
	}

	console.log('checkUploadsStatus result:', result);

	return result;

}

export const sync = async () => {

	authorized();

	let result = await axiosInstance.get('/applications/sync');

	return result.data;

};

export const bankVerification = async (id) => {

	authorized();

	let result = await axiosInstance.get(`/applications/bank-verification/${id}`);

	return result.data;

};